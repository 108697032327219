@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,600,700,800,900&display=swap");


@font-face {
	font-family: dinNext_medium;
	src: url(../../../assets/fonts/DINNextLTPro-Regular.ttf) format("opentype");
}

// themes - our custom or/and out of the box themes
@import "themes";

// framework component themes (styles tied to theme variables)
@import "~@nebular/theme/styles/globals";
// @import "~@nebular/auth/styles/all";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$enable-dark-mode: false;

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";

// loading progress bar theme
@import "./pace.theme";

@import "./layout";
@import "_overrides";

// install the framework and custom global styles
@include nb-install() {
	// framework global styles
	@include nb-theme-global();
	// @include nb-auth-global();

	@include ngx-layout();
	// loading progress bar
	@include ngx-pace-theme();

	@include nb-overrides();
}

.redRow {
	background-color: #e80e2e !important;
	color: white !important;
}

.disableRow {
	background-color: #636363 !important;
	color: white !important;
	cursor: default !important;
}

.greenRow {
	background-color: #00d68f !important;
	color: white !important;
}

.orangeRow {
	background-color: #ffaa00 !important;
	color: white !important;
}

i.fa.fa-remove {
	&::after {
		border-radius: 17px;
		content: "\2716";
		background-color: #dc3545;
		color: #fff;
		padding: 0.25rem 0.5rem 0.3rem .5rem;
		/* margin-top: 1rem; */
		font-style: normal;
		font-weight: 600;
		text-transform: Capitalize;
		white-space: nowrap;
	}
}

i.fa.fa-refresh.fa-spin.button-icon-right {
	display: none;
}

a[ref="fileLink"] {
	color: #BF3B00 !important;

	&:hover {
		color: #FF4E00 !important;
	}
}

a[ref="fileBrowse"] {
	color: white;
	padding: 0.25rem 0.6rem 0.4rem;
	border-radius: 0.25rem;
	background: #007bff;
	font-weight: 600;
	margin-left: 0.25rem;

	&:hover {
		background: #0069d9;
		color: white;
		text-decoration: none;
	}
}

.leaflet-popup-content {
	text-align: center;
	font-weight: bold;
}

.leaflet-popup-content-wrapper {
	background: #FF4E00;
	color: black;
}

a.leaflet-popup-close-button {
	color: black !important;
}

.leaflet-container a.leaflet-popup-close-button:hover {
	color: black;
}

.leaflet-top,
.leaflet-bottom {
	position: absolute;
	z-index: 400; //overwrite default value -> 1000
	pointer-events: none;
}

.input-group-text {
	padding: 0rem 0.5rem !important;
}

i.fa.fa-calendar {
	&::after {
		border-radius: 17px;
		content: "\1F4C6";
		color: #fff;
		font-style: normal;
		font-weight: 600;
		text-transform: Capitalize;
		white-space: nowrap;
		filter: saturate(0.01);
		font-size: larger;
	}
}

i.fa.fa-times-circle-o {
	&::after {
		border-radius: 17px;
		content: "\2716";
		//background-color: #dc3545;
		color: #fff;
		//padding: .25rem .5rem 0.3rem.5rem;
		/* margin-top: 1rem; */
		font-style: normal;
		font-weight: 600;
		text-transform: Capitalize;
		white-space: nowrap;
	}
}

.signature-pad-body {
	a.signature-pad-refresh {
		padding: 0.5rem 0.2rem;
	}
}

i.fa.fa-refresh {
	visibility: hidden;

	&::after {
		visibility: visible;
		border-radius: 17px;
		content: "\2716";
		//background-color: #dc3545;
		color: #696969;
		//padding: .25rem .5rem 0.3rem.5rem;
		/* margin-top: 1rem; */
		font-style: normal;
		text-transform: Capitalize;
		white-space: nowrap;
		font-style: normal;
		font-size: 1.2rem;
		font-weight: bold;
	}
}

::-webkit-scrollbar {
	width: 0.5rem !important;
	height: 0.5rem !important;
}

::-webkit-scrollbar-track {
	background-color: transparent !important;
	border-radius: 10px !important;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px !important;
	background: #999 !important;
	width: 0.5rem;
}

.scrollable-container {
	overflow: hidden !important;
}

#sidebar {
	margin-top: -4.75rem !important;
}

.layout-container {
	padding-top: 0rem !important;
	margin-top: 4.75rem !important;
}

// Telerik styles

.k-calendar .k-content>table,
.k-calendar .k-content>.k-calendar-table,
.k-calendar .k-calendar-content>table,
.k-calendar .k-calendar-content>.k-calendar-table {
	table-layout: fixed;
	margin-right: -17px;
	width: 238px;
}

.k-cell-inner>.k-link {
	padding: 8px 12px;
	width: auto;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	flex: 1;
	overflow: hidden;
	justify-content: center;
	font-weight: bold;
}

.k-grid-header .k-header {
	position: relative;
	vertical-align: top;
	cursor: default;
	text-align: center;
	font-weight: bold;
	word-break: break-word;
	white-space: pre-line;
}

.k-column-title {
	min-width: 0;
	text-overflow: ellipsis;
	white-space: inherit;
	flex-shrink: 1;
	overflow: visible;
}

.k-grid .k-grid-edit-row td:not(.k-hierarchy-cell),
.k-grid .k-command-cell,
.k-grid .k-edit-cell {
	padding: 3px 12px !important;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

.nb-theme-default nb-menu .menu-item a:hover,
.nb-theme-default nb-menu .menu-item a:hover .menu-icon {
	color: nb-theme(color-primary-800) !important;
}

.nb-theme-default nb-menu .menu-item a.active,
.nb-theme-default nb-menu .menu-item a.active .menu-icon {
	color: nb-theme(color-primary-500) !important;
}

.nb-theme-default nb-sidebar.compacted nb-menu .menu-item a.active::before {
	background: nb-theme(color-primary-500) !important;
}

.nb-theme-default [nbButton].appearance-filled.status-primary {
	background: nb-theme(color-primary-500) !important;
	border-color: nb-theme(color-primary-500) !important;
}

.nb-theme-default [nbButton].appearance-filled.status-primary:hover {
	background: nb-theme(color-primary-800) !important;
	border-color: nb-theme(color-primary-800) !important;
}

.nb-theme-default nb-card.accent-info {
	border-top-color: nb-theme(color-primary-500) !important;
}

kendo-grid-toolbar.k-toolbar.k-grid-toolbar.ng-star-inserted {
	position: absolute;
	right: 0;
	top: -3.3rem;
	left: 0.6rem;
	background: none;
	border: none;
	z-index: 1;
}

.k-touch-action-none.k-grid-draggable-header.k-group-indicator {
	color: nb-theme(color-primary-800);
	border: 2px solid nb-theme(color-primary-500);
	border-radius: 0.25rem;

	.k-link {
		color: nb-theme(color-primary-800);
	}
}

.k-grid-header .k-i-sort-asc-sm,
.k-grid-header .k-i-sort-desc-sm,
.k-grid-header .k-sort-order {
	color: #424242;
}

.k-filtercell .k-filtercell-operator>.k-button:not(.k-clear-button-visible) {
	display: none;
}

.k-filtercell {
	.k-dropdown {
		width: 100%;
	}
}

.k-pager-numbers .k-link.k-state-selected {
	color: nb-theme(color-primary-800);
	background: none;
}

span.whole-cell.ng-star-inserted {
	a {
		color: nb-theme(color-primary-500) !important;

		&:active,
		&:hover {
			color: nb-theme(color-primary-800) !important;
		}
	}
}

.k-pager-numbers .k-link {
	color: nb-theme(color-primary-500) !important;
}

a.k-link.k-pager-nav,
a.k-link.k-pager-nav.k-pager-last {
	color: nb-theme(color-primary-800) !important;
}

nb-option.ng-star-inserted.selected {
	color: nb-theme(color-primary-700) !important;
}

nb-option.ng-star-inserted {
	&:hover {
		color: nb-theme(color-primary-500) !important;
	}
}

kendo-dropdownlist.k-widget.k-dropdown::selection {
	background-color: nb-theme(color-primary-500) !important;
}

.table-buttons {
	justify-content: space-evenly;

	.table-buttons-button {
		padding: 0.2rem 0.5rem;
		color: #888;
		border-radius: 4px;
		margin-left: 0.2rem;

		&:first-child {
			margin-left: 0rem;
		}
	}

	.button-view {
		border: 1px solid #ddd;
	}

	.table-buttons-button.k-primary {
		color: white;
	}
}

button.appearance-filled.btn-disabled.size-medium.status-primary.shape-rectangle.transitions {
	color: white;
	filter: grayscale(1) opacity(0.5);
}

button.k-primary.k-button.k-grid-edit-command.ng-star-inserted {
	background-color: nb-theme(color-primary-500) !important;
	border-color: nb-theme(color-primary-500) !important;
	box-shadow: none !important;
}

.nb-theme-default nb-checkbox.status-basic .custom-checkbox.checked {
	background-color: nb-theme(color-primary-500) !important;
	border-color: nb-theme(color-primary-500) !important;
}

.nb-theme-default nb-option.selected {
	background: none !important;
}

.k-cell-inner {
	display: inline-flex;
	width: 100%;
}

a.k-grid-column-menu.k-grid-filter {
	background: none !important;
	text-decoration: none !important;
}

th:first-child.k-header {
	.k-grid-column-menu {
		position: absolute;
		top: 0;
		right: 0;
	}
}

.k-columnmenu-item {
	box-shadow: none !important;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
	border-color: nb-theme(color-primary-500) !important;
	background-color: nb-theme(color-primary-500) !important;
}

.k-checkbox {
	outline: none !important;
	box-shadow: none !important;
}

.k-primary {
	border-color: nb-theme(color-primary-500) !important;
	background-color: nb-theme(color-primary-500) !important;
	outline: none !important;
	box-shadow: none !important;
	background-image: none;

	&:hover {
		border-color: nb-theme(color-primary-600) !important;
		background-color: nb-theme(color-primary-600) !important;
	}
}

.textButton.active {
	color: var(--color-primary-500) !important;
	font-weight: bolder;
}

th.k-header.k-filterable.k-grid-header-sticky.ng-star-inserted {
	background-color: var(--color-primary-300);
}

td.k-touch-action-auto.ng-star-inserted,
td.ng-star-inserted {
	padding: 0.2rem 0.4rem !important;
}

.k-grid {
	font-size: 12px !important;
}

span.whole-cell.ng-star-inserted {
	padding: 0 !important;
}

.content {
	height: fit-content;
	overflow: auto !important;
}

nb-tooltip {
	background-color: var(--color-primary-500) !important;

	.content {
		height: fit-content !important;
	}

	span.arrow {
		border-bottom-color: var(--color-primary-500) !important;
	}
}

@import "./forms.scss";
@import "./workflow.scss";