@import "./themes";

@mixin nb-overrides() {
	nb-select.size-medium button {
		padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;
		nb-icon {
			right: 0.41rem !important;
		}
	}
	
	table tr th {
		padding: 0.3rem 0.6rem !important;
		background-color: #FF4E00;
	}

	table tr td {
		padding: 0.3rem 0.6rem !important;
	}

	table tr th a {
		font-weight: 700 !important;
		color: black !important;
	}
}
