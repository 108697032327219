@import "themes";

.workflow_file {
	cursor: pointer;
	color: #000 !important;
	font-size: 0.7375rem !important;
	font-family: Open Sans, sans-serif;
	font-weight: 400 !important;
	text-decoration: none !important;

	&:hover {
		color: nb-theme(color-primary-700) !important;
		font-weight: 600;
	}

	&:active,
	&:focus {
		color: nb-theme(color-primary-500) !important;
		font-weight: 600;
	}
}
