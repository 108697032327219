@use 'themes' as *;

@use '@nebular/theme/styles/globals' as *;

//@import "./app/@theme/styles/styles.scss";

@include nb-install() {
  @include nb-theme-global();
};
/* You can add global styles to this file, and also import other style files */

.contract-manager {
	width: 100% !important;

	.cdk-visually-hidden {
		width: 99% !important;
		height: 99% !important;
	}

	nb-dialog-container {
		min-width: 50%;
		width: 100% !important;
		height: 100% !important;
	}
}

nb-toast.status-success {
    background: #2ce69b !important;
    border-color: #2ce69b !important;
    color: nb-theme(toastr-success-text-color) !important;

    .title {
      color: nb-theme(toastr-success-text-color) !important;
    }

    &.destroy-by-click:hover {
      background: #95F2CD !important;
      border-color: #95F2CD !important; //
    }

    .icon-container {
      background: #fff !important;
      color: #2ce69b !important;
    }
  }

.flatpickr-time.time24hr {
  height: fit-content !important;
}

.columns {
  overflow: hidden;
}