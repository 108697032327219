// @nebular theming framework
@import "~@nebular/theme/styles/theming";
// @nebular out of the box themes
@import "~@nebular/theme/styles/themes";
@import "~@nebular/theme/styles/themes/default";

$nb-enable-css-custom-properties: true;

$nb-themes: nb-register-theme(
	(
		layout-padding-top: 0.25rem,
		layout-padding: 1rem 1rem 0,
		menu-item-icon-margin: 0 0.5rem 0 0,
		card-height-tiny: 13.5rem,
		card-height-small: 21.1875rem,
		card-height-medium: 28.875rem,
		card-height-large: 36.5625rem,
		card-height-giant: 44.25rem,
		card-margin-bottom: 1.875rem,
		card-header-with-select-padding-top: 0.5rem,
		card-header-with-select-padding-bottom: 0.5rem,
		card-header-text-font-size: 1rem,
		select-min-width: 6rem,
		slide-out-background: #f7f9fc,
		slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
		slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
		color-primary-100: #d6ecd2,
		color-primary-200: #b7ddb0,
		color-primary-300: #99d18f,
		color-primary-400: #7bc86c,
		color-primary-500: #61bd4f,
		color-primary-600: #5aac44,
		color-primary-700: #519839,
		color-primary-800: #49852e,
		color-primary-900: #3f6f21,
		color-info-500: color-primary-500,
		color-danger-500: #e80e2e,
		color-success-500: #fcc000,
		//rgb(25, 172, 17),
		color-success-hover: #ca9a00,
		// rgb(20, 223, 9),
		color-primary-focus: color-primary-500,
		color-primary-hover: color-primary-800,
		text-basic-color: black,
		text-control-color: black,
		text-alternate-color: black,
		text-subtitle-font-size: 0.8rem,
		text-paragraph-font-size: 0.7375rem,
		text-paragraph-line-height: 0.9rem,
		tabset-content-padding: 0.5rem 0.75rem,
		tabset-tab-padding: 0.5rem,
		list-item-padding: 0.3rem,
		input-placeholder-text-color: color-primary-900,
		input-border-color: color-primary-200,
		input-focus-border-color: color-primary-400,
		text-disabled-color: rgba(155, 164, 181, 1),
	),
	default,
	default
);

$nb-themes: nb-register-theme(
	(
		layout-padding-top: 2.25rem,
		menu-item-icon-margin: 0 0.5rem 0 0,
		card-height-tiny: 13.5rem,
		card-height-small: 21.1875rem,
		card-height-medium: 28.875rem,
		card-height-large: 36.5625rem,
		card-height-giant: 44.25rem,
		card-margin-bottom: 1.875rem,
		card-header-with-select-padding-top: 0.5rem,
		card-header-with-select-padding-bottom: 0.5rem,
		select-min-width: 6rem,
		slide-out-background: #DF4400,
		slide-out-shadow-color: 2px 0 3px #DF4400,
		slide-out-shadow-color-rtl: -2px 0 3px #DF4400,
	),
	cosmic,
	cosmic
);

$nb-themes: nb-register-theme(
	(
		layout-padding-top: 2.25rem,
		menu-item-icon-margin: 0 0.5rem 0 0,
		card-height-tiny: 13.5rem,
		card-height-small: 21.1875rem,
		card-height-medium: 28.875rem,
		card-height-large: 36.5625rem,
		card-height-giant: 44.25rem,
		card-margin-bottom: 1.875rem,
		card-header-with-select-padding-top: 0.5rem,
		card-header-with-select-padding-bottom: 0.5rem,
		select-min-width: 6rem,
		slide-out-background: linear-gradient(270deg, #edf1f7 0%, #e4e9f2 100%),
		slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
		slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
	),
	corporate,
	corporate
);

$nb-themes: nb-register-theme(
	(
		layout-padding-top: 2.25rem,
		menu-item-icon-margin: 0 0.5rem 0 0,
		card-height-tiny: 13.5rem,
		card-height-small: 21.1875rem,
		card-height-medium: 28.875rem,
		card-height-large: 36.5625rem,
		card-height-giant: 44.25rem,
		card-margin-bottom: 1.875rem,
		card-header-with-select-padding-top: 0.5rem,
		card-header-with-select-padding-bottom: 0.5rem,
		select-min-width: 6rem,
		slide-out-background: linear-gradient(270deg, #222b45 0%, #BF3B00 100%),
		slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
		slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
	),
	dark,
	dark
);

//.nb-theme-default .options-list-container.appearance-outline.status-basic {
//	width: fit-content !important;
//}

.nb-menu.context-menu {
	text-align: left !important;
	min-width: max-content;
}

.nb-theme-default nb-select.size-giant:not(.full-width),
.nb-theme-default .options-list:not(.full-width) {
	max-width: -webkit-fill-available !important;
}

.nb-theme-default nb-menu .menu-item .menu-icon {
	min-width: 24px;
	max-width: 24px;
}
