@import "themes";


#formio {

	--ws-color: #61bd4f;
	--ws-font: "Open sans";

	font-family: var(--ws-font) !important;

	.alert {
		background: #fff;
		border: 1px solid #ccc;
		border-radius: 0.5rem;
		font-size: 1.2rem;
		padding: 1.5rem 2rem;
		margin-bottom: 1.5rem;
	}

	.alert-success {
		background: #b4ffb4;
	}

	.formio-component-htmlelement {

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: var(--ws-font) !important;
		}
	}

	.formio-component-panel {

		.card {
			position: relative;
			padding: .5rem;
			border-radius: 6px;

			.card {
				border: none;
				padding: 0;
			}

			.formio-component-tabs {

				.card-body {
					margin-top: 5px;
				}
			}

			.formio-component-panel {
				.card-body {
					margin-top: 0;
				}
			}

			.bg-primary {
				background-color: var(--ws-color) !important;
			}

			.card-header {
				width: -moz-fit-content;
				width: fit-content;
				padding: 0.8rem 1.5rem 0.3rem 1.5rem;
				border-radius: 10px 10px 0px 0px;
				margin-bottom: -1px;

				.card-title {
					font-size: 1.2rem;
					font-family: var(--ws-font) !important;
					font-weight: 400;
				}
			}

			.card-body {

				padding: 2rem .5rem;
				border-top: 3px var(--ws-color) solid;

				.form-group {
					position: relative;
				}

				.formio-hidden {
					margin: 0;
				}

				.formio-component {
					.input-group-append {
						font-size: 1.6rem;
						position: absolute;
						top: 0rem;
						right: 0;
						height: 100%;
						width: 2rem;
						padding: 1.1rem 0rem;
						color: #696969;
					}
				}

				.formio-component-htmlelement {

					h1,
					h2,
					h3,
					h4,
					h5,
					h6 {
						margin-top: 0;
						font-family: var(--ws-font) !important;
					}
				}

				.formio-component-select {
					background-color: white;

					.col-form-label {
						position: absolute;
						top: -10px;
						left: 14px;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						;
						color: #666;
					}

					.formio-errors {
						font-size: .8rem;
						margin-bottom: 2rem;
					}

					.formio-choices {
						.form-control {
							padding: .8rem 1.8rem;
							box-shadow: none;
							background: inherit;
							min-height: 3.125rem;
							border: 1px solid #ccc;
							border-radius: 5px;
							font-size: 1rem;
							overflow: hidden;

							&:disabled {
								border-style: dotted;
								color: #888;
							}

							&:focus {
								border-color: var(--ws-color);
								border-width: 2px;
							}

							input {
								background: inherit;
							}
						}

						.is-invalid {
							border-color: #DC3545;

							&:focus {
								border-color: #DC3545;
								border-width: 2px;
							}
						}

						.form-control[disabled] {
							border-style: dotted;
						}
					}
				}

				.formio-component-tags {
					background-color: white;

					.col-form-label {
						position: absolute;
						top: -10px;
						left: 14px;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						;
						color: #666;
					}

					.choices {
						.choices__inner {
							background: white;
							padding: .8rem 1.8rem;
							box-shadow: none;
							background: inherit;
							border-color: #ced4da;

							.choices__input {
								background: white;
							}
						}
					}

					.is-disabled {
						.choices__inner {
							border-style: dotted;
							color: #888;
						}
					}

					.formio-errors {
						font-size: .8rem;
						margin-bottom: 2rem;
					}
				}

				.is-disabled .choices__list--multiple .choices__item {
					background: #bbb;
					border-radius: 3rem;
				}

				.choices__list--multiple .choices__item[data-deletable] {
					background: #999;
					border: none;
					padding: .4rem 1rem;
					border-radius: 3rem;
					color: white;

					button {
						border: none;
						border-radius: 3rem;
						color: white;
					}
				}

				.formio-component-textfield {
					background-color: white;

					.col-form-label {
						position: absolute;
						top: -10px;
						left: 14px;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						;
						color: #666;
					}

					.form-control {
						padding: 0.7rem 1.8rem;
						box-shadow: none;
						min-height: 3.125rem;
						border: 1px solid #ccc;
						border-radius: 5px;
						font-size: 1rem;

						&:focus {
							border-color: var(--ws-color) !important;
							border-width: 2px;
						}

						&:disabled {
							background: inherit;
							color: #888;
							border-style: dotted;
						}
					}

					.is-invalid {
						border-color: #DC3545;

						&:focus {
							border-color: #DC3545;
							border-width: 2px;
						}
					}

					.formio-errors {
						margin-bottom: 1rem;

						.error {
							display: block;
							margin-top: 0.25rem;
							padding-left: 0.5rem;
							font-size: 0.8rem;
						}
					}

				}

				.formio-component-number {
					background-color: white;


					.col-form-label {
						position: absolute;
						top: -10px;
						left: 14px;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
					}

					.form-control {
						padding: .8rem 1.8rem;
						box-shadow: none;
						min-height: 3.125rem;
						border: 1px solid #ccc;
						border-radius: 5px;
						font-size: 1rem;

						&:focus {
							border-color: var(--ws-color);
							border-width: 2px;
						}

						&:disabled {
							background-color: white;
							border-style: dotted;
						}
					}

					.is-invalid {
						border-color: #DC3545;

						&:focus {
							border-color: #DC3545;
							border-width: 2px;
						}
					}

					.formio-errors {
						font-size: .8rem;
						margin-bottom: 2rem;
					}
				}

				.formio-component-datetime {
					background-color: white;

					.col-form-label {
						position: absolute;
						top: -10px;
						left: 14px;
						z-index: 4;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
					}

					.input-group {
						width: 100%;
					}

					.form-control {
						padding: .8rem 1.8rem;
						box-shadow: none;
						border-radius: 4px !important;
						min-height: 3.125rem;
						min-width: 13rem;
						border: 1px solid #ccc;
						border-radius: 5px;
						font-size: 1rem;

						&:focus {
							border-color: var(--ws-color);
							border-width: 2px;
						}

						&:disabled {
							background-color: white;
							color: #888;
						}
					}

					.is-invalid {
						border-color: #DC3545;

						&:focus {
							border-color: #DC3545;
							border-width: 2px;
						}
					}

					.input-group-text {
						display: none;
					}
				}

				.formio-component-time {
					background-color: white;

					.col-form-label {
						position: absolute;
						top: -10px;
						left: 14px;
						z-index: 4;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
					}

					.input-group {
						width: 100%;
					}

					.form-control {
						padding: .8rem 1.8rem;
						box-shadow: none;
						border-radius: 4px !important;
						min-height: 3.125rem;
						min-width: 13rem;
						border: 1px solid #ccc;
						border-radius: 5px;

						&:focus {
							border-color: var(--ws-color);
							border-width: 2px;
						}

						&:disabled {
							background-color: white;
							color: #888;
						}
					}

					.is-invalid {
						border-color: #DC3545;

						&:focus {
							border-color: #DC3545;
							border-width: 2px;
						}
					}

					.input-group-text {
						display: none;
					}
				}

				.formio-component-textarea {
					background-color: white;
					width: 100%;

					.card-body {
						padding: 2rem 1rem;
						border: 1px solid #999;
						border-radius: 4px;
						padding: .8rem 1.8rem;
						box-shadow: none;
						min-height: 3.125rem;

						.formio-editor-read-only-content {
							color: #999;
						}
					}

					.col-form-label {
						position: absolute;
						top: -10px;
						left: 14px;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
					}

					.form-control {
						padding: .8rem 1.8rem;
						box-shadow: none;
						min-height: 3.125rem;
						border: 1px solid #ccc;
						border-radius: 5px;
						width: 100%;

						&:focus {
							border-color: var(--ws-color);
							border-width: 2px;
						}

						&:disabled {
							background: inherit;
							color: #888;
							border-style: dotted;
						}
					}

					.is-invalid {
						border-color: #DC3545;

						&:focus {
							border-color: #DC3545;
							border-width: 2px;
						}
					}

					.formio-errors {
						font-size: .8rem;
						margin-bottom: 2rem;
					}
				}

				.formio-component-customMap {
					border-style: dotted;
					border-color: #777;
					border-width: 1px;
					border-radius: 4px;
					background: white;

					.col-form-label {
						top: -10px;
						position: absolute;
						left: 14px;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
					}

					.form-control-custom-field {
						padding: .8rem 1.8rem;
						box-shadow: none;
						min-height: 3.125rem;

						&:focus {
							border-color: var(--ws-color);
							border-width: 2px;
						}

						&:disabled {
							background-color: white;
							border-style: dotted;
							color: 888;
						}
					}

					.is-invalid {
						border-color: #DC3545;

						&:focus {
							border-color: #DC3545;
							border-width: 2px;
						}
					}

					.formio-errors {
						font-size: .8rem;
						margin-bottom: 2rem;
					}
				}

				.formio-component-file {
					background-color: white;

					.fileSelector {
						font-size: .8rem;
						padding-bottom: 3rem;

						a[ref="toggleCameraMode"] {
							display: block;
							margin: 1rem 0rem;
							color: #007BFF
						}
					}

					.col-form-label {
						position: absolute;
						top: -11px;
						left: 14px;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
					}

					div {
						div {
							img {
								width: 200px;
								margin: 2rem 0rem;
								color: #888;
							}
						}
					}

					ul {
						color: #888;
						min-height: .1rem;
						border-radius: 0;
						font-size: 0.8rem;

						.list-group-header {

							border-top-right-radius: 4px;
							border-top-left-radius: 4px;
						}

						.list-group-item:last-child {
							border-bottom-right-radius: 4px;
							border-bottom-left-radius: 4px;
						}
					}

					.form-text {
						font-size: .8rem;
						margin-top: -1.5rem;
					}

					.form-control {
						padding: .8rem 1.8rem;
						box-shadow: none;
						min-height: 3.125rem;
						border: 1px solid #ccc;
						border-radius: 5px;

						&:focus {
							border-color: var(--ws-color);
							border-width: 2px;
						}

						&:disabled {
							background-color: white;
							border-style: dotted;
							color: 888;
						}
					}

					.is-invalid {
						border-color: #DC3545;

						&:focus {
							border-color: #DC3545;
							border-width: 2px;
						}
					}

					.formio-errors {
						font-size: .8rem;
						margin-bottom: 2rem;
					}

					.browse {
						line-height: 1.7rem;
					}
				}

				.formio-component-radio {
					background-color: white;
					margin: 1rem 0rem .5rem 0rem;

					.col-form-label {
						position: absolute;
						top: -10px;
						left: -7px;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
					}

					.radio {
						padding: 1.5rem 0rem 1rem 0rem;
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						align-content: center;
						justify-content: flex-start;
						align-items: center;
						grid-column-gap: 1.5rem;
						column-gap: 1.5rem;
						grid-row-gap: 1rem;
						row-gap: 1rem;

						.form-check {
							position: relative;
							width: 100%;
							padding: 0 0 0 1rem;
							margin-top: 0.3rem;
							margin-left: 1.5rem;

							.form-check-label {

								font-size: 1rem;

								&::before {
									border: 2px solid #777;
									border-radius: 5rem;
									content: ".";
									color: transparent;
									background: transparent;
									height: 17px;
									width: 17px;
									position: absolute;
									top: 1px;
									left: -1px;
								}

								input {
									display: none;
								}

								span {
									margin-left: 1.3rem;
									padding: 1rem;
								}
							}
						}

						.form-check-inline {
							position: relative;
							padding: 0;
							flex-grow: 1;
							min-width: fit-content;
							margin: 0 !important;

							.form-check-label {

								font-size: 1rem;

								&::before {
									border: 2px solid #777;
									border-radius: 5rem;
									content: ".";
									color: transparent;
									background: transparent;
									height: 17px;
									width: 17px;
									position: absolute;
									top: 1px;
									left: -1px;
								}

								input {
									display: none;
								}

								span {
									margin-left: 1.3rem;
									padding: 1rem;
								}
							}
						}

						.radio-selected {
							position: relative;

							.form-check-label {

								font-size: 1rem;

								&::before {
									border: 2px solid var(--ws-color);
									border-radius: 5rem;
									content: ".";
									color: transparent;
									background: transparent;
									height: 17px;
									width: 17px;
									position: absolute;
									top: 1px;
									left: -1px;
								}

								&::after {
									border-radius: 5rem;
									content: ".";
									color: transparent;
									background: var(--ws-color);
									height: 9px;
									width: 9px;
									position: absolute;
									top: 5px;
									left: 3px;
								}

								input {
									display: none;
								}

								span {
									margin-left: 1.3rem;
									padding: 1rem;
								}
							}
						}
					}

				}

				.formio-component-button {
					.btn {
						font-family: Roboto;
						height: 50px;
						max-height: 50px;
						padding: 0 2rem;
						text-transform: uppercase;
						background-color: var(--ws-color) !important;
						border: 1px solid var(--ws-color);
						box-shadow: none;
						border-radius: 6px;
						color: white;
						font-weight: bold;
						cursor: pointer;
						font-size: 1.2rem;
					}
				}

				.formio-component-selectboxes {
					background-color: white;
					margin-top: 3rem;
					margin-bottom: 2rem;

					.col-form-label {
						position: absolute;
						top: -1.7rem;
						left: 0rem;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
					}

					.radio {
						padding: 0rem;

						.form-check {
							position: relative;
							margin-top: 0.7rem;
							margin-left: 2rem;

							.form-check-label {
								font-size: 1rem;

								.form-check-input {
									visibility: hidden;
									margin-top: -0.1rem;
								}

								.form-check-input::before {
									visibility: visible;
									content: ".";
									border: 2px solid #666;
									color: transparent;
									width: 1.2rem;
									border-radius: 2px;
									height: 1.2rem;
									position: absolute;
									top: .7rem;
									left: -1rem;
								}

								.form-check-input:checked::before {
									visibility: visible;
									content: ".";
									background: var(--ws-color);
									color: transparent;
									width: 1.2rem;
									border-radius: 2px;
									height: 1.2rem;
									position: absolute;
									top: .7rem;
									left: -1rem;
									border: none;
								}

								.form-check-input:checked::after {
									transform: rotate(40deg);
									visibility: visible;
									content: ".";
									border: 2px solid white;
									color: transparent;
									width: 0.4rem;
									height: .9rem;
									position: absolute;
									top: .7rem;
									left: -1rem;
									border-top: none;
									border-left: none;
								}

								.form-check-input[checked="true"]::before {
									visibility: visible;
									content: ".";
									background: var(--ws-color);
									color: transparent;
									width: 1.2rem;
									border-radius: 2px;
									height: 1.2rem;
									position: absolute;
									top: .7rem;
									left: -1rem;
									border: none;
								}

								.form-check-input[checked="true"]::after {
									transform: rotate(40deg);
									visibility: visible;
									content: ".";
									border: 2px solid white;
									color: transparent;
									width: 0.4rem;
									height: .9rem;
									position: absolute;
									top: .7rem;
									left: -.6rem;
									border-top: none;
									border-left: none;
								}

								span {
									margin-left: 1rem;
								}
							}
						}
					}
				}

				.formio-component-checkbox {
					background-color: white;

					.col-form-label {
						position: absolute;
						top: -10px;
						left: 14px;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
					}

					.checkbox {
						padding: 1.5rem 3.5rem;

						.form-check-label {
							font-size: 1rem;

							.form-check-input {
								visibility: hidden;
								margin-top: -0.1rem;
							}

							.form-check-input::before {
								visibility: visible;
								content: ".";
								border: 2px solid #666;
								color: transparent;
								width: 1.2rem;
								border-radius: 2px;
								height: 1.2rem;
								position: absolute;
								top: .6rem;
								left: -1rem;
							}

							.form-check-input:checked::before {
								visibility: visible;
								content: ".";
								background: var(--ws-color);
								color: transparent;
								width: 1.2rem;
								border-radius: 2px;
								height: 1.2rem;
								position: absolute;
								top: .6rem;
								left: -1rem;
								border: none;
							}

							.form-check-input:checked::after {
								transform: rotate(40deg);
								visibility: visible;
								content: ".";
								border: 2px solid white;
								color: transparent;
								width: 0.4rem;
								height: .9rem;
								position: absolute;
								top: .6rem;
								left: -1rem;
								border-top: none;
								border-left: none;
							}

							.form-check-input[checked="true"]::before {
								visibility: visible;
								content: ".";
								background: var(--ws-color);
								color: transparent;
								width: 1.2rem;
								border-radius: 2px;
								height: 1.2rem;
								position: absolute;
								top: .6rem;
								left: -1rem;
								border: none;
							}

							.form-check-input[checked="true"]::after {
								transform: rotate(40deg);
								visibility: visible;
								content: ".";
								border: 2px solid white;
								color: transparent;
								width: 0.4rem;
								height: .9rem;
								position: absolute;
								top: .15rem;
								left: 6px;
								border-top: none;
								border-left: none;
							}

							span {
								margin-left: 1rem;
								color: #777;
							}
						}
					}
				}

				.checkboxes-deluxe,
				.checkboxes-deluxe-w1,
				.checkboxes-deluxe-w2,
				.checkboxes-deluxe-w3,
				.checkboxes-deluxe-w4 {
					background-color: white;
					margin: 1rem 0rem 0.5rem 0rem;

					.col-form-label {
						position: relative;
						top: -0rem !important;
						left: 0rem !important;
						z-index: 1;
						font-weight: 300;
						font-size: 1rem;
						background: none;
						padding: 0rem;
						color: #666;
						width: -moz-fit-content;
						width: fit-content;
						max-width: calc(100% - 2rem);
						white-space: wrap;
						overflow: hidden;
						text-overflow: ellipsis;
						line-height: 1.4rem;

						&::before {
							content: " ";
							position: absolute;
							z-index: -1;
							top: 10px;
							left: 0;
							height: 2px;
							width: 100%;
							background: white;
						}
					}

					.form-radio {}

					.radio {
						padding: 0.5rem 0rem 1rem 0rem;
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						align-content: center;
						justify-content: flex-start;
						align-items: center;
						column-gap: 0.5rem;
						row-gap: 0.5rem;

						.form-check-inline,
						.form-check {
							position: relative;
							padding: 0;
							flex-grow: 1;
							min-width: fit-content;
							margin: 0 0 0.5rem 0 !important;

							.form-check-label {
								font-size: 1rem;
								padding: 1rem 1.5rem;
								text-transform: uppercase;
								width: 100%;
								text-align: center;

								&::before {
									border: 2px solid #777;
									border-radius: 4px;
									content: ".";
									color: transparent;
									background: transparent;
									height: 100%;
									width: 100%;
									position: absolute;
									top: 0;
									left: 0;
								}

								input {
									visibility: visible;
									height: 100%;
									width: 100%;
									display: none;
								}

								span {
									white-space: wrap;
								}
							}
						}

						.form-check {
							position: relative;
							width: 5rem;
							height: 2.9rem;
							flex: 1 0 25%;

							.form-check-label {
								font-size: 1rem;
								padding: 1rem 1.5rem;
								border-radius: 4px;
								text-transform: uppercase;
								width: 100%;
								text-align: center;
								position: absolute;
								top: 0;
								left: 0;

								&::before {
									padding: 0.75rem 2rem;
								}

								input {
									display: none;
								}

								span {
									margin-left: 0rem;
								}
							}
						}

						.radio-selected {
							position: relative;

							.form-check-label {
								font-size: 1rem;
								padding: 1rem 1.5rem;
								background-color: var(--ws-color);
								border-radius: 4px;
								text-transform: uppercase;
								width: 100%;
								text-align: center;

								&::before {
									border: 2px solid var(--ws-color);
									border-radius: 4px;
									content: ".";
									color: transparent;
									background: transparent;
									height: 100%;
									width: 100%;
									position: absolute;
									top: 0;
									left: 0;
								}

								input {
									visibility: visible;
									height: 100%;
									width: 100%;
									display: none;
								}

								span {
									color: white;
									white-space: wrap;
								}
							}
						}
					}
				}

				.radio-deluxe-buttons,
				.radio-deluxe-buttons-w1,
				.radio-deluxe-buttons-w2,
				.radio-deluxe-buttons-w3,
				.radio-deluxe-buttons-w4 {
					background-color: white;
					margin: 1rem 0rem 0.5rem 0rem;

					.col-form-label {
						position: relative;
						top: -0rem !important;
						left: 0rem !important;
						z-index: 1;
						font-weight: 300;
						font-size: 1rem;
						background: none;
						padding: 0rem;
						color: #666;
						width: -moz-fit-content;
						width: fit-content;
						max-width: calc(100% - 2rem);
						white-space: wrap;
						overflow: hidden;
						text-overflow: ellipsis;
						line-height: 1.4rem;

						&::before {
							content: " ";
							position: absolute;
							z-index: -1;
							top: 10px;
							left: 0;
							height: 2px;
							width: 100%;
							background: white;
						}
					}

					.radio {
						padding: 0.5rem 0rem 1rem 0rem;
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						align-content: center;
						justify-content: flex-start;
						align-items: center;
						column-gap: 0.5rem;
						row-gap: 0.5rem;

						.form-check-inline,
						.form-check {
							position: relative;
							padding: 0;
							flex-grow: 1;
							min-width: fit-content;
							margin: 0 0 0.5rem 0 !important;

							.form-check-label {
								font-size: 1rem;
								padding: 1rem 1.5rem;
								text-transform: uppercase;
								width: 100%;
								text-align: center;

								&::before {
									border: 2px solid #777;
									border-radius: 4px;
									content: ".";
									color: transparent;
									background: transparent;
									height: 100%;
									width: 100%;
									position: absolute;
									top: 0;
									left: 0;
								}

								input {
									visibility: visible;
									height: 100%;
									width: 100%;
									display: none;
								}

								span {
									//margin-left: 1.3rem;
									white-space: wrap;
								}
							}

							// &:hover {
							// 	border-radius: 5px;
							// 	background: nb-theme(color-primary-300);
							// 	color: white;
							// }
						}

						.form-check {
							position: relative;
							width: 5rem;
							height: 2.9rem;
							flex: 1 0 25%;

							.form-check-label {
								font-size: 1rem;
								padding: 1rem 1.5rem;
								border-radius: 4px;
								text-transform: uppercase;
								width: 100%;
								text-align: center;
								position: absolute;
								top: 0;
								left: 0;

								&::before {
									padding: 0.75rem 2rem;
								}

								input {
									display: none;
								}

								span {
									margin-left: 0rem;
								}
							}
						}

						.radio-selected {
							position: relative;

							.form-check-label {
								font-size: 1rem;
								padding: 1rem 1.5rem;
								background-color: var(--ws-color);
								border-radius: 4px;
								text-transform: uppercase;
								width: 100%;
								text-align: center;

								&::before {
									border: 2px solid var(--ws-color);
									border-radius: 4px;
									content: ".";
									color: transparent;
									background: transparent;
									height: 100%;
									width: 100%;
									position: absolute;
									top: 0;
									left: 0;
								}

								input {
									visibility: visible;
									height: 100%;
									width: 100%;
									display: none;
								}

								span {
									//margin-left: 1.3rem;
									color: white;
									white-space: wrap;
								}
							}
						}
					}
				}

				.checkboxes-deluxe-w1,
				.radio-deluxe-buttons-w1 {
					.radio {
						.form-check {
							flex: 1 0 95%;
							height: 2.1rem;
						}
					}
				}

				.checkboxes-deluxe-w2,
				.radio-deluxe-buttons-w2 {
					.radio {
						.form-check {
							flex: 1 0 45%;
						}
					}
				}

				.checkboxes-deluxe-w3,
				.radio-deluxe-buttons-w3 {
					.radio {
						.form-check {
							flex: 1 0 25%;
						}
					}
				}

				.checkboxes-deluxe-w4,
				.radio-deluxe-buttons-w4 {
					.radio {
						.form-check {
							flex: 1 0 15%;
						}
					}
				}

				.formio-component-editgrid {
					.editgrid-listgroup {
						.list-group-item {
							position: relative;
							display: block;
							padding: 10px 15px;
							margin-bottom: -1px;
							background-color: #fff;
							border: 1px solid #ddd;

							.row {
								display: flex;
								flex-wrap: wrap;
								margin-right: -15px;
								margin-left: -15px;
								align-items: center;

								.col-sm-2 {
									position: relative;
									width: 100%;
									padding-right: 15px;
									padding-left: 15px;
									color: #666;
									font-weight: 300;
									font-size: .8rem;
									flex: 0 0 16.6666666667%;
									max-width: 16.6666666667%;
								}

								.col-sm-3 {
									-webkit-box-flex: 0;
									-ms-flex: 0 0 25%;
									flex: 0 0 25%;
									max-width: 25%;
									position: relative;
									width: 100%;
									padding-right: 15px;
									padding-left: 15px;
									color: #666;
									font-weight: 300;
									font-size: .8rem;
								}

								.col-sm-4 {
									.btn-default {
										font-size: inherit;
									}

									.btn-danger {
										font-size: inherit;
									}
								}
							}
						}
					}
				}

				.formio-component-datagrid {
					background-color: white;
					width: 100%;

					.col-form-label {
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
						padding-bottom: .3rem;
					}

					.table {
						display: table;
						border-radius: 4px;
						overflow: visible;
						table-layout: auto !important;

						th {
							background: none;
							padding: 1rem 1.5rem !important;
							font-size: .8rem;
						}

						td {
							padding: 1rem !important;
						}

						.radio {
							.form-check-label {
								padding-left: 0px;
							}

							.form-check-input {
								margin-top: .05rem;
							}
						}
					}

					.btn {
						font-family: Roboto;
						font-weight: 400;
						height: 50px;
						max-height: 50px;
						padding: 0 1.5rem;
						text-transform: uppercase;
						font-size: 1rem;
						background-color: var(--ws-color) !important;
						border-color: var(--ws-color);
					}

					.radio {
						padding: 1.5rem;
						margin: 0px;
					}

					.choices__list--dropdown {
						max-width: 50vw;
					}

				}

				.formio-component-survey {
					background-color: white;

					.col-form-label {
						position: absolute;
						top: -10px;
						left: 14px;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
					}

					input {
						visibility: hidden;
						margin: 0;
						position: absolute;
						top: 50%;
						left: 50%;
						-ms-transform: translate(-50%, -50%);
						transform: translate(-50%, -50%);
					}

					input::before {
						visibility: visible;
						content: ".";
						border: 2px solid #666;
						color: transparent;
						width: 1.5rem;
						border-radius: 2px;
						height: 1.5rem;
						position: absolute;
					}

					input:checked::before {
						visibility: visible;
						content: ".";
						background: var(--ws-color);
						color: transparent;
						width: 1.5rem;
						border-radius: 2px;
						height: 1.5rem;
						position: absolute;
						border: none;
					}

					input:checked::after {
						transform: rotate(40deg);
						visibility: visible;
						content: ".";
						border: 2px solid white;
						color: transparent;
						width: 0.7rem;
						height: 1.1rem;
						position: absolute;
						border-top: none;
						border-left: none;
						left: 4px;
					}

					.table {
						thead {
							tr {
								th {
									position: relative;
									padding: 1.3rem 1.6rem !important;
								}
							}
						}

						tbody {
							tr {
								td:not(:first-child) {
									position: relative;
									padding: 1.3rem 1.6rem !important;
								}
							}
						}
					}
				}

				.formio-component-url {
					background-color: white;

					.col-form-label {
						position: absolute;
						top: -10px;
						left: 14px;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
					}

					.form-control {
						padding: .8rem 1.8rem;
						box-shadow: none;
						min-height: 3.125rem;
						border: 1px solid #ccc;
						border-radius: 5px;

						&:focus {
							border-color: var(--ws-color);
							border-width: 2px;
						}

						&:disabled {
							background: inherit;
							color: #888;
							border-style: dotted;
						}
					}

					.is-invalid {
						border-color: #DC3545;

						&:focus {
							border-color: #DC3545;
							border-width: 2px;
						}
					}

					.formio-errors {
						font-size: .8rem;
						margin-bottom: 2rem;
					}
				}

				.formio-component-phoneNumber {
					background-color: white;

					.col-form-label {
						position: absolute;
						top: -10px;
						left: 14px;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
					}

					.form-control {
						padding: .8rem 1.8rem;
						box-shadow: none;
						min-height: 3.125rem;
						border: 1px solid #ccc;
						border-radius: 5px;

						&:focus {
							border-color: var(--ws-color);
							border-width: 2px;
						}

						&:disabled {
							background: inherit;
							color: #888;
							border-style: dotted;
						}
					}

					.is-invalid {
						border-color: #DC3545;

						&:focus {
							border-color: #DC3545;
							border-width: 2px;
						}
					}

					.formio-errors {
						font-size: .8rem;
						margin-bottom: 2rem;
					}
				}

				.formio-component-address {
					background-color: white;

					.col-form-label {
						position: absolute;
						top: -10px;
						left: 14px;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
					}

					.form-control {
						padding: .8rem 1.8rem;
						box-shadow: none;
						min-height: 3.125rem;
						border: 1px solid #ccc;
						border-radius: 5px;

						&:focus {
							border-color: var(--ws-color);
							border-width: 2px;
						}

						&:disabled {
							background: inherit;
							color: #888;
							border-style: dotted;
						}
					}

					.is-invalid {
						border-color: #DC3545;

						&:focus {
							border-color: #DC3545;
							border-width: 2px;
						}
					}

					.formio-errors {
						font-size: .8rem;
						margin-bottom: 2rem;
					}
				}

				.formio-component-password {
					background-color: white;

					.col-form-label {
						position: absolute;
						top: -10px;
						left: 14px;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
					}

					.form-control {
						padding: .8rem 1.8rem;
						box-shadow: none;
						min-height: 3.125rem;
						border: 1px solid #ccc;
						border-radius: 5px;

						&:focus {
							border-color: var(--ws-color);
							border-width: 2px;
						}

						&:disabled {
							background: inherit;
							color: #888;
							border-style: dotted;
						}
					}

					.is-invalid {
						border-color: #DC3545;

						&:focus {
							border-color: #DC3545;
							border-width: 2px;
						}
					}

					.formio-errors {
						font-size: .8rem;
						margin-bottom: 2rem;
					}
				}

				.formio-component-currency {
					background-color: white;

					.col-form-label {
						position: absolute;
						top: -10px;
						left: 14px;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
					}

					.form-control {
						padding: .8rem 1.8rem;
						box-shadow: none;
						min-height: 3.125rem;
						border: 1px solid #ccc;
						border-radius: 5px;
						font-size: 1rem;

						&:focus {
							border-color: var(--ws-color);
							border-width: 2px;
						}

						&:disabled {
							background: inherit;
							color: #888;
							border-style: dotted;
						}
					}

					.is-invalid {
						border-color: #DC3545;

						&:focus {
							border-color: #DC3545;
							border-width: 2px;
						}
					}

					.formio-errors {
						font-size: .8rem;
						margin-bottom: 2rem;
					}
				}

				.formio-component-signature {
					background-color: white;

					.col-form-label {
						position: absolute;
						top: -10px;
						left: 24px;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
					}

					.signature-pad-body {
						border-radius: 4px;
						width: -webkit-fill-available;
						padding: 0px;
						margin: 0px;
						max-width: 100%;
						border: 1px solid #ced4da;
						background: white;

						.field-required-inline {
							font-size: .8rem;
							top: -8px;
							right: -8px;
						}

						.signature-pad-refresh {
							right: 0.5rem;
							top: 0.5rem;
							text-decoration: none;
						}
					}

					.signature-pad-footer {
						text-align: left;
						color: #999;
						font-family: Roboto;
						font-size: .8rem;
					}
				}

				.formio-component-email {
					background-color: white;

					.col-form-label {
						position: absolute;
						top: -10px;
						left: 14px;
						z-index: 1;
						font-weight: 300;
						font-size: .8rem;
						background: inherit;
						padding: 0rem 0.5rem;
						color: #666;
					}

					.form-control {
						padding: .8rem 1.8rem;
						box-shadow: none;
						min-height: 3.125rem;
						border: 1px solid #ccc;
						border-radius: 5px;

						&:focus {
							border-color: var(--ws-color);
							border-width: 2px;
						}

						&:disabled {
							background: inherit;
							color: #888;
							border-style: dotted;
						}
					}

					.is-invalid {
						border-color: #DC3545;

						&:focus {
							border-color: #DC3545;
							border-width: 2px;
						}
					}

					.formio-errors {
						font-size: .8rem;
						margin-bottom: 2rem;
					}
				}

				.formio-component-panel {
					.card {
						padding: 0;
						border: none !important;

						.card-header {
							border: none;
							width: fit-content;
							padding: 0.5rem 1.5rem 0.4rem 1.5rem;

							.card-title {
								font-size: .9rem;
								font-family: var(--ws-font) !important;
							}
						}

						.card-body {
							border-top: 3px var(--ws-color) solid;
						}
					}
				}
			}
		}

		.form-control[disabled],
		.form-control[readonly],
		fieldset[disabled] {
			color: #999;
			border-style: dotted;
		}
	}

	.formio-component-tabs {
		.card {
			.card-header {
				background: #fff;
				border: none;
				width: 100%;

				.card-header-tabs {
					border-bottom: 3px solid var(--ws-color);

					.nav-item {
						.nav-link {
							margin-bottom: -3px;
							border: 3px solid transparent;
							border-top-left-radius: 0.5rem;
							border-top-right-radius: 0.5rem;
							text-decoration: none;
							font-family: Roboto;
							color: var(--ws-color);
							background: none;
							font-size: .9rem;
							height: 2.6rem;

							&:hover {
								border: 3px solid transparent;
								background-color: var(--ws-color);
								height: 2.6rem;
								color: white;
							}
						}

						.nav-link.active {
							color: #fff;
							background-color: var(--ws-color);
							border: 3px solid var(--ws-color) !important;
							text-decoration: none;
							font-weight: 400;
							font-size: .9rem;

							&:hover {
								height: 2.6rem;
							}
						}
					}
				}
			}

			.card-body {
				padding: 2rem 1.5rem;
				border: none;

				.formio-component-panel {
					.card {
						padding: 0;
						border: none !important;

						.card-header {
							border: none;
							width: fit-content;
							padding: 0.5rem 1.5rem 0.4rem 1.5rem;

							.card-title {
								font-size: .9rem;
								font-family: var(--ws-font) !important;
							}
						}

						.card-body {
							border-top: 3px var(--ws-color) solid;

							.formio-component-tabsDentroDeUnPanel {
								.card {
									.card-header {
										width: 100%;
									}

									.card-body {
										margin-top: 5px;
										border: none;

										.formio-component-panel {
											.card {
												padding: 0;
												border: none !important;

												.card-header {
													border: none;
													width: fit-content;
												}

												.card-body {
													border-top: 3px var(--ws-color) solid;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.formio-component-fieldset {}
}

a[ref="fileBrowse"] {
	color: white;
	padding: 0.25rem 0.6rem 0.4rem;
	border-radius: 0.25rem;
	background: var(--ws-color);
	font-weight: 600;
	margin-left: 0.25rem;

	&:hover {
		background: var(--ws-color);
		color: white;
		text-decoration: none;
	}
}

a[ref=toggleCameraMode] {
	color: var(--ws-color) !important;
	text-decoration: none;

	&:hover {
		color: var(--ws-color) !important;
		text-decoration: none;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	background: white !important;
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

.btn-outline-primary {
	font-family: Roboto;
	background-color: var(--ws-color);
	color: #FFF;
	font-weight: 400;
	height: 50px;
	max-height: 50px;
	padding: 0 2rem;
	text-transform: uppercase;
	font-size: 1.5rem;
	outline: none !important;

	&:disabled {
		background-color: #999;
	}
}

.signature-pad-body {
	a.signature-pad-refresh {
		padding: 0.5rem 0.2rem;
	}
}

i.fa.fa-refresh {
	visibility: hidden;

	&::after {
		visibility: visible;
		border-radius: 17px;
		content: "\2716";
		color: #696969;
		font-style: normal;
		text-transform: Capitalize;
		white-space: nowrap;
		font-style: normal;
		font-size: 1.2rem;
		font-weight: bold;
	}
}

span.flatpickr-day.selected,
span.flatpickr-day.today.selected {
	background-color: var(--ws-color) !important;
	border-color: var(--ws-color) !important;
}

.formio-component-form {
	background: white;
	border: 1px solid #ddd;
	border-radius: 0.5rem;
}

.formio-component-panel {

	.card-header {
		color: #fff;
	}
}


input {
	width: 100%;
}

.formio-component-select,
.formio-component-tags,
.formio-component-textfield,
.formio-component-number,
.formio-component-datetime,
.formio-component-time,
.formio-component-textarea,
.formio-component-editgrid,
.formio-component-datagrid,
.formio-component-survey,
.formio-component-url,
.formio-component-phoneNumber,
.formio-component-address,
.formio-component-password,
.formio-component-currency,
.formio-component-signature,
.formio-component-email {

	.col-form-label {
		top: -7px !important;
		left: 10px !important;
		font-size: 1rem !important;
		line-height: 1.4rem !important;
	}
}

.formio-component-htmlelement {
	.text-big {
		line-height: 1.4rem !important;
	}
}

.bottom-margin {
	margin-bottom: 2rem !important;
}

.sr-only {
	display: none;
}

.external-label {
	.col-form-label {
		position: relative !important;
    	padding: 0 !important;
		background: none !important;
		left: 0 !important;
	}
}

@media (max-width: 768px) and (max-width: 850px) {

	.checkboxes-deluxe,
	.radio-deluxe-buttons,
	.checkboxes-deluxe-w4,
	.radio-deluxe-buttons-w4 {
		.radio {
			.form-check {
				flex: 1 0 25% !important;
				height: 3.9rem !important;
			}
		}
	}
}

@media (min-width: 551px) and (max-width: 768px) {

	.checkboxes-deluxe,
	.radio-deluxe-buttons,
	.checkboxes-deluxe-w3,
	.radio-deluxe-buttons-w3,
	.checkboxes-deluxe-w4,
	.radio-deluxe-buttons-w4 {
		.radio {
			.form-check {
				flex: 1 0 45% !important;
				height: 3.9rem !important;
			}
		}
	}

	.checkboxes-deluxe-w1,
	.radio-deluxe-buttons-w1,
	.checkboxes-deluxe-w2,
	.radio-deluxe-buttons-w2 {
		.radio {
			.form-check {
				height: 3.9rem !important;
			}
		}
	}
}

@media (min-width: 0px) and (max-width: 550px) {

	.checkboxes-deluxe,
	.radio-deluxe-buttons,
	.checkboxes-deluxe-w1,
	.radio-deluxe-buttons-w1,
	.checkboxes-deluxe-w2,
	.radio-deluxe-buttons-w2,
	.checkboxes-deluxe-w3,
	.radio-deluxe-buttons-w3,
	.checkboxes-deluxe-w4,
	.radio-deluxe-buttons-w4 {
		.radio {
			.form-check {
				flex: 1 0 95% !important;
				height: 3.9rem !important;
			}
		}
	}
}